import {watchEffect, ref, onMounted, onBeforeUnmount} from 'vue';
import {useRoute} from '#imports';

export function useHeaderSearchVisibility() {
    const searchIsVisible = ref(false);
    const pathsWithSearch = ['/', 's'];
    const route = useRoute();
    const scrollpx = ref(0);

    function onScroll() {
        scrollpx.value = window.scrollY;
    }

    onMounted(() => {
        window.addEventListener('scroll', onScroll);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('scroll', onScroll);
    });

    watchEffect(() => {
        searchIsVisible.value = !pathsWithSearch.includes(route.path) || scrollpx.value > 600;
    });

    return {searchIsVisible};
}

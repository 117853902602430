<template>
    <div>
        <SudosuFrame />
        <CountryLaunchingBanner />
        <UnsubscribeInvitationBanner />
        <NavbarUnlogged :show-search="searchIsVisible" />
        <slot />
        <!-- Event when logged in, we want to display this footer -->
        <FooterUnlogged :always-visible="true" />
    </div>
</template>

<script setup lang="ts">
    import {FooterUnlogged, NavbarUnlogged, SudosuFrame} from '#components';
    import CountryLaunchingBanner from '~/components/CountryLaunchingBanner.vue';
    import UnsubscribeInvitationBanner from '~/components/UnsubscribeInvitationBanner.vue';
    import {useHeaderSearchVisibility} from '~/composables/useHeaderSearchVisibility';

    const {searchIsVisible} = useHeaderSearchVisibility();
</script>
